<template>
  <section id="check">
    <div class="checkout_wrap pd_b50">
      <div class="remainder_txt">
        <p class="stitle" v-html="$t('payMessage.outstandingBalance')" />
      </div>
      <div class="pay_list_wrap">
        <div class="pay_title remainder">
          {{ $t("main.outstandingHistory") }}
        </div>
        <dl class="pay_list">
          <dt>그릴</dt>
          <dd>{{ $t("common.won", ["80,000"]) }}</dd>
          <dt>{{ $t("main.paymentRequestAmount") }}</dt>
          <dd class="c_r">{{ $t("common.won", ["80,000"]) }}</dd>
        </dl>
      </div>
      <div class="pay_list_wrap line_none">
        <div class="pay_title">
          {{ $t("main.mySettlementHistory") }}
          <!-- <a id="btn_my" class="btn_open" /> -->
        </div>
        <div id="my_pay_list">
          <dl class="pay_list">
            <dt>{{ $t("main.settlementAmount") }}</dt>
            <dd>{{ $t("common.won", ["180,000"]) }}</dd>
            <dt>{{ $t("main.amountPaid") }}</dt>
            <dd class="c_b">{{ $t("common.won", ["0"]) }}</dd>
            <dt>{{ $t("main.paymentRequestAmount") }}</dt>
            <dd class="c_r">{{ $t("common.won", ["720,000"]) }}</dd>
          </dl>
        </div>
      </div>
      <div class="btn_pay_wrap btn_pay_fixed">
        <a class="btn_point">{{ $t("common.pay") }}</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PayRemainder",
};
</script>
